import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';

import pic1 from '../assets/images/thermostat.jpg';
import pic2 from '../assets/images/thermowells.jpg';
import pic3 from '../assets/images/pulley_spacer.jpg';

const CncServicesPage = props => (
  <Layout>
    <Helmet>
      <title>JIT Machine Corp</title>
      <meta name="description" content="CNC Machining Services" />
    </Helmet>

    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>CNC Services</h1>
        </header>
        <div className="content">
          <p>
            Just in Time Machine Corp. provides CNC machining services to
            produce complex precision machined parts. Our 3 and 4 axis CNC mills
            and lathes are state-of-the-art which reduces your price per part,
            especially on prototype or short production runs.
          </p>
          <p>
            We are the one-stop destination for all of your machining needs
            whether it's fabrication, welding, grinding, assembly, finishing and
            heat treating.
          </p>
        </div>
      </div>
    </section>

    <div id="main">
      <section id="two" className="spotlights">
        <section>
          <div className="image">
            <img src={pic1} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>MATERIALS & PART SIZE</h3>
              </header>
              <p>
                From Tungsten to stainless to plastics and everything in
                between, we have experience working with whatever material suits
                your needs. We can handle parts as large as 20”x40”.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={pic2} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>EXPERIENCE & QUALITY CONTROL</h3>
              </header>
              <p>
                We have over 40 years of CNC machining experience working with a
                variety of industries including Medical, Aerospace, Agricultural
                and Automotive. We are a minority owned small business with
                experience working with the government as well as
                sub-contractors and tier two suppliers. (Certification #:
                WI-2507-MBE). Our quality department maintains strict quality
                control standards and is managed by a degreed mechanical
                engineer. We are ISO complaint and inspect first pieces on all
                production runs. We consistently hold tolerances as close as
                .0002".
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={pic3} alt="" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>JUST IN TIME PARTS AND QUICK TURNAROUND ON QUOTES</h3>
              </header>
              <p>
                Just in Time not only applies to a quick turnaround for your
                parts but also to quotes as well. In fact, we will respond to
                your RFQ within 24 hours. Send us your CAD files or design
                drawings and we will produce complex precision machined parts to
                meet your needs.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
);

export default CncServicesPage;
